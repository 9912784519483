<template>
    <div class="toast-header">
        <strong class="me-auto">{{title}}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body" style="background: inherit;" ref="toastBodyRef">
        <div style="white-space: pre-wrap;">{{message}}</div>
        <input v-if="textInputRequired" v-model="inputValue" v-focus:100 type="text" @keydown.enter="handleConfirm()" @keydown.esc="hideToast()" class="form-control">
        <div class="mt-2 pt-2 border-top d-flex">
            <template v-if="buttons">
                <button v-for="(customButton, index) in buttons"
                    class="btn btn-sm" @click="() => customButton.action(customResolve, customReject)"
                    :class="[index===0?'ms-auto':'ms-1', customButton.class ?? 'btn-outline-primary']">
                    {{customButton.text}}
                </button>
            </template>
            <template v-else>
                <button @click="handleConfirm" type="button" class="btn btn-sm cBtnOk ms-auto" :class="btnClassOk">{{btnTextOk}}</button>
                <button type="button" class="btn btn-sm ms-1" :class="btnClassCancel" data-bs-dismiss="toast">{{btnTextCancel}}</button>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
interface IConfirmProps {
    message: string
    iconClass?: string,
    btnClassOk?: string,
    btnClassCancel?: string,
    btnTextOk?: string,
    btnTextCancel?: string,
    title?: string,
    textInputRequired?: boolean,
    initialTextInputValue?: string,
    resolveConfirm: (input: string, pCustom?: any) => void,
    rejectConfrim: (pReason: any) => void,
    buttons?: { class?: string, text?: string, action: ((pConfrim: () => void, pCancel: () => void) => void) }[],
};
</script>

<script setup lang="ts">
import { ref } from 'vue';

const props = withDefaults(defineProps<IConfirmProps>(), {
    title: 'Confirm action',
    btnClassCancel: 'btn-outline-primary',
    btnClassOk: 'btn-primary',
    btnTextOk: 'Ok',
    btnTextCancel: 'Cancel',
});

const inputValue = ref(props.initialTextInputValue ?? '');
const toastBodyRef = ref<HTMLElement|null>(null);

function customResolve(pValue?: any) {
    props.resolveConfirm(inputValue.value, pValue);
    hideToast();
}

function customReject(pValue?: any) {
    props.rejectConfrim(pValue);
    hideToast();
}

function handleConfirm() {
    props.resolveConfirm(inputValue.value);
    hideToast();
}

function hideToast() {
    const toastElement = toastBodyRef.value?.closest('.toast');
    if (!toastElement) { return; }
    const toastInstance =  window['bootstrap'].Toast.getInstance(toastElement);
    toastInstance.hide();
}

</script>